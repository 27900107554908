@import "~@fontsource/nunito/scss/mixins";

// Uses a unicode-range map to automatically generate multiple @font-face rules.
@include fontFace(
  $weight: 400,
  $display: fallback,
  $fontDir: "~@fontsource/nunito/files"
);

// Bootstrap customisations
$font-family-sans-serif:      Nunito, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:            $font-family-sans-serif !default;
// stylelint-enable value-keyword-case

$font-size-base:              0.95rem !default; // Assumes the browser default, typically `16px`
$font-size-lg:                $font-size-base * 1.25 !default;
$font-size-sm:                $font-size-base * .875 !default;

$border-radius:               .15rem;

.card {
  box-shadow: 0px 0px 4px 1px #DDDDDD;
}

.img-thumbnail {
  padding: 0 !important;
  box-shadow: 0px 0px 4px 1px #DDDDDD;
}

@import "node_modules/bootstrap/scss/bootstrap";

.native-validate input:invalid {
  @extend .is-invalid;
}

.slide-enter-active {
    -moz-transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -moz-transition-timing-function: ease-in;
    -webkit-transition-timing-function: ease-in;
    -o-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
 }
 
 .slide-leave-active {
    -moz-transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
 }
 
 .slide-enter-to, .slide-leave {
    max-height: 100px;
    overflow: hidden;
 }
 
 .slide-enter, .slide-leave-to {
    overflow: hidden;
    max-height: 0;
 }


 .slide-left-enter-active,
 .slide-left-leave-active,
 .slide-right-enter-active,
 .slide-right-leave-active {
   transition-duration: 0.5s;
   transition-property: height, opacity, transform;
   transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
   overflow: hidden;
 }
 
 .slide-left-enter,
 .slide-right-leave-active {
   opacity: 0;
   transform: translate(2em, 0);
 }
 
 .slide-left-leave-active,
 .slide-right-enter {
   opacity: 0;
   transform: translate(-2em, 0);
 }

 
.fade-enter-active, .fade-leave-active {
   transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
   opacity: 0;
}

.fade-skeleton-enter-active, .fade-skeleton-leave-active {
   transition: opacity .1s;
}
.fade-skeleton-enter, .fade-skeleton-leave-to {
   opacity: 0.5;
}


.fadeHeight-enter-active,
.fadeHeight-leave-active {
  transition: all 0.2s;
  max-height: 230px;
}
.fadeHeight-enter,
.fadeHeight-leave-to
{
  opacity: 0;
  max-height: 0px;
}

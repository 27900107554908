

























































.lds-ring {
    display: inline-block;
    position: relative;
}
.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;

    /* ratio: calc(64px / 80px) */
    width: 80%;
    height: 80%;

    /* ratio: calc(8px / 80px) */
    margin: 10%;
    border: 8px solid #fff;
    border-radius: 50%;
    border-color: #fff transparent transparent transparent;
    animation-name: lds-ring;
    animation-timing-function: cubic-bezier(0.2, 0, 0.5, 1);
    animation-iteration-count: infinite;
}
@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

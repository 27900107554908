@import "./bootstrap.scss";

html, body, .container-fluid {
  height:100%;
}

$main_navbar_height: 52px;
$header_height: 35px;
$sidebar_width: 256px;
$sidebar_collapse_width: 50px;

@media only screen and (min-width: 992px) {
  /* For desktop ( lg > ) */
  .sidebar {
      min-height: 100%;
      width: $sidebar_width;
  }

  .sidebar-header {
    width: $sidebar_width !important;
  }

  .sidebar-hide-md {
    max-width: 0% !important;
    padding: 0;
  }
}

@media only screen and (min-width: 768px) {
  /* For non-mobile ( md > ) */
  .sidebar {
    min-height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1020;
    display: flex;
  }

  .sidebar-header {
    width: 100%;
  }

  .sidebar-hide-md {
    max-width: 0% !important;
    padding: 0;
  }
  
  .sidebar-collapse {
    width: $sidebar_collapse_width;
  }

  .sidebar-collapse .nav-link-text {
    display: none;
  }
  
  .sidebar-expand .nav-link-text {
    display: inline;
  }

  .app-content-sidebar-expanded {
    margin-left: $sidebar_width !important;
  }
  
  .app-content-sidebar-collapsed {
    margin-left: $sidebar_collapse_width !important;
  }

  .btn-md-normal {
    display: inline-block !important;
    width: auto !important;
  }

  .w-md-auto {
    width: auto !important;
  }
}

/* Custom CSS Breakpoint for small devices */ 
@media (max-width: 767.98px) { 
  .ml-ss-auto, .mx-ss-auto {
    margin-left: auto !important;
  }
  .mr-ss-auto, .mx-ss-auto {
    margin-right: auto !important;
  }
  .text-ss-center {
    text-align: center !important;
  }
  .sidebar {
    max-height: 100%;
    width: 100%;
    overflow-y: hidden;
  }

  .sidebar-header {
    width: 100%;
  }

  .sidebar-hide {
    max-height: 0% !important;
  }

  .sidebar-collapse-action {
    display: none !important;
  }
}

.sidebar-collapse-action {
  display: block;
  position: absolute;
  top: $main_navbar_height + 25px;
  right: -15px;
  background-color: $light;
  padding: 3px;
  border-top-right-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  border: 1px solid $gray-200;
  border-left: 0;
  cursor: pointer;
  z-index: -1;
}

.sidebar-sticky {
  position: relative;
  display: flex;
  flex-direction: column;
  bottom: 0;
  min-width: 100%;
}

.sidebar-border {
  display: flex;
  flex-direction: column;
}

pre {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 90%;
  white-space: pre-wrap;
}

.sidebar {
  transition: all 1s;
}

.sidebar .nav {
  overflow-y: hidden;
}

.nav-fix {
  /* stop the page always scrolling when sticky navbar shown */
  /* set to the height of the navbar */
  margin-top: -($main_navbar_height + 24px);
  padding-top: ($main_navbar_height + 24px);
}

.navbar-brand {
  overflow: hidden;
}

.main-nav {
  z-index: 1018;
}

.sidebar-logo-container {
  height: $main_navbar_height;
  overflow-x: visible;
  width: $sidebar_collapse_width;
}

.sidebar-logo-container img {
  max-width: auto;
  max-height: 100%;
}

.btn-navbar {
  font-size:20px;
  border-radius: 10em;
}

.app-content {
  min-height: 100%;
  overflow: hidden;
  transition: all 1s;
}

article {
  width: 36em;
  max-width: 100%;
  line-height: 1.5;
  font-size: 16px;
}

legend.bold, label.bold {
  font-weight: bold;
  font-size: 18px;
}

.todo {
  font-weight: bold;
  color: #ff00ff;
  font-style: italic;
  text-shadow: 0 0rem 0.5rem rgba(0, 0, 0, .5);
}

.box-shadow { 
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.cursor-link {
  cursor: pointer;
}

/* No decoration text links */
a.deco-none {
  color: inherit;
  text-decoration:none;
}

.deco-none:link {
  color: inherit;
  text-decoration: inherit;
}

.deco-none:hover {
  color: inherit;
  text-decoration: inherit;
}

/* Corner Ribbons */
.corner-ribbon {
  width: 200px;
  top: 25px;
  left: -50px;
  text-align: center;
  line-height: 50px;
  letter-spacing: 1px;
  color: #f0f0f0;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  z-index: 1;
  position: absolute;
}

.corner-ribbon-container {
  overflow: hidden;
}

.corner-ribbon.shadow {
  box-shadow: 0 0 3px rgba(0,0,0,.3);
}

.corner-ribbon.bottom-left {
  top: auto;
  bottom: 25px;
  left: -50px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.badge-status {
  font-size: 1.1em;
  color: #f0f0f0;
  text-align: center;
  letter-spacing: 1px;
  box-shadow: 0 0 3px rgba(0,0,0,.3);
  padding: 8px;
}

/* Corner Ribbon Colours */
.corner-ribbon.white, .badge-white { 
  background: #f0f0f0; color: #555;
}
.corner-ribbon.black, .badge-black, td.cell-black {
  background: #333;
}
.corner-ribbon.grey, .badge-grey, td.cell-grey {
  background: #999;
}
.corner-ribbon.blue, .badge-blue, td.cell-blue {
  background: rgb(48, 146, 211);
}
.corner-ribbon.green, .badge-green, td.cell-green {
  background: rgb(4, 148, 28);
}
.corner-ribbon.turquoise, .badge-turquoise, td.cell-turquoise {
  background: rgb(10, 180, 146);
}
.corner-ribbon.purple, .badge-purple, td.cell-purple {
  background: rgb(133, 39, 180);
}
.corner-ribbon.red, .badge-red, td.cell-red {
  background: rgb(211, 42, 26);
}
.corner-ribbon.orange, .badge-orange, td.cell-orange {
  background: rgb(231, 143, 55);
}
.corner-ribbon.yellow, .badge-yellow, td.cell-yellow {
  background: #efd426;
}

.corner-ribbon.peach, .badge-peach, td.cell-yellow {
  background: #ff9999;
}

.btn-purple {
  @include button-variant($purple, $purple);
}

.btn-outline-purple {
  @include button-outline-variant($purple);
}

.btn-indigo {
  @include button-variant($indigo, $indigo);
}

.btn-outline-indigo {
  @include button-outline-variant($indigo);
}

.btn-teal {
  @include button-variant($teal, $teal);
}

.btn-outline-teal {
  @include button-outline-variant($teal);
}

.btn-xs, .btn-group-xs > .btn {
  padding: 0.25rem 0.25rem;
  font-size: 0.75rem;
  line-height: 1.2;
  border-radius: 0.2rem;
}

.btn-pulse {
  animation: pulsing 1.25s cubic-bezier(0.66, 0, 0, 1) infinite;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5);
}

@keyframes pulsing {
  to {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
  }
}


.intrinsic {
  display: block;
  position: relative;
  height: 0;
  padding-top: 100%;
  overflow: hidden;
}

.intrinsic--4x3 {
  padding-top: 75% !important;
}

.intrinsic--3x2 {
  padding-top: 66.67% !important;
}

.intrinsic--16x9 {
  padding-top: 56.25% !important;
}

.intrinsic-item {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.intrinsic-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background:	$gray-600;
  color: inherit;
}

.intrinsic-icon .fa {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.ws-notification {
  padding: 30px;
  margin: 0 5px 5px;

  font-size: 16px;

  color: #000;
  background: #b4d9fc;
  border: 1px solid #187FE7;
  border-left: 5px solid #187FE7;
}

.meme-cutout-shadow {
  filter: drop-shadow(0 0 10px #000);
}


.rounded-left-0 { 
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.rounded-right-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.comma-list:not(:first-child) {
  margin-left: 0em;  
}

.comma-list:first-child:empty ~ .comma-list:not(:empty) {
  margin-left: 0;  
}

.comma-list:first-child:empty ~ .comma-list:not(:empty) ~ .comma-list:not(:empty) {
  margin-left: -.5em;  
}

.comma-list:empty {
  display: none;
}

.comma-list:not(:first-child):before {
  content: ", ";
}

.comma-list:empty + .comma-list:not(:empty):before {
  content : "";
}

.comma-list:not(:empty) ~ .comma-list:empty + .comma-list:not(:empty):before {
  content : ", ";
}

.align-nav-middle ul {
  // For use with bootstrap-vue nav components
  // centres them vertically in the row
  margin-top: 0;
  margin-bottom: 0;
}

.image-area {
  position: relative;
  width: 100%;
  border-radius: 6px;
  box-shadow: 0px 0px 4px 1px #DDDDDD;
}

.image-area img {
  max-width: 100%;
  height: auto;
  border-radius: 3px;
}
